<section>
  <div class="footer-container">
    <div class="footer">
      <div class="footer-logo">
        <h1 class="logo">
          <span>C</span><span>A</span><span>R</span
          ><span class="accent-o">O</span>
        </h1>
        <a routerLink="/impressum" class="imprint-link-desktop">{{ 'footer.imprint' | translate }}</a>
      </div>
      <p class="copy">&copy; Caroline Willers 2024</p>
      <div class="footer-icons">
        <a href="https://github.com/CaroWillers" target="_blank">
          <img src="/assets/icons/github.png" alt="github Icon" />
        </a>
        <a href="#contact">
          <img src="assets/icons/email.png" alt="Email Icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/caro-willers-marketing-specialist/"
          target="_blank"
        >
          <img src="/assets/icons/linkedin.png" alt="LinkedIn Icon" />
        </a>
      </div>
    </div>
    <div class="imprint-mobile">
      <a routerLink="/impressum" class="imprint-link-mobile">{{
        "footer.imprint" | translate
      }}</a>
    </div>
  </div>
</section>
