<!-- impressum.component.html -->
<div class="go-back-container">
  <a routerLink="/" class="go-back-link">
    <img
      class="go-back-btn"
      src="/assets/icons/arrowupDefault.png"
      alt="back to startpage"
    />
    <span class="go-back-text">{{ "impressum.back" | translate }}</span>
  </a>
</div>

<div class="impressum">
  <h1>{{ "impressum.title" | translate }}</h1>

  <p>
    {{ "impressum.name" | translate }}<br />
    {{ "impressum.address" | translate }}
  </p>

  <h2>{{ "impressum.contact" | translate }}</h2>
  <p>
    {{ "impressum.phone" | translate }}<br />
    {{ "impressum.email" | translate }}
  </p>

  <h2>{{ "impressum.responsible" | translate }}</h2>
  <p>{{ "impressum.name" | translate }}</p>
</div>
