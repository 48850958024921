<section id="hero" class="hero">
  <div class="hero-container">
    <div class="profile-picture">
      <div class="profile-shadow">
        <img
          src="assets/bgr-img/1_photo-shadows.png"
          alt="Background Picture"
        />
      </div>
      <div class="profile-photo">
        <img
          src="assets/caro.png"
          alt="My Profile Picture"
        />
      </div>
    </div>

    <div class="hero-text-container" pAnimateOnScroll>
      <div class="hero-text"> 
        <div class="item-1" [ngClass]="{'lang-de': currentLang === 'de'}" >{{ "hero.intro" | translate }}</div>
        <div class="item-2">{{ "hero.name" | translate }}</div>
        <div class="item-3">{{ "hero.position" | translate }}</div>
        <div class="item-5">
          <button class="btn-primary-green" (click)="scrollToContact()">
            {{ "hero.button" | translate }}
          </button>          
        </div>
      </div>
      <div class="reference-icons-mobile">
        <a href="https://github.com/CaroWillers" target="_blank">
          <img src="assets/icons/github.png" alt="github Icon" />
        </a>
        <a href="#contact">
          <img src="assets/icons/email.png" alt="Email Icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/caro-willers-marketing-specialist/"
          target="_blank"
        >
          <img src="assets/icons/linkedin.png" alt="LinkedIn Icon" />
        </a>
        <a href="#contact" class="contact-link">
          {{ "hero.contact_email" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="swosh">
    <img class="swoshImage" src="assets/bgr-img/2_hero-background.png" alt="Swosh" />
  </div>
  <div class="reference-icons-desktop">
    <a href="https://github.com/CaroWillers" target="_blank">
      <img src="assets/icons/github.png" alt="github Icon" />
    </a>
    <a href="#contact">
      <img src="assets/icons/email.png" alt="Email Icon" />
    </a>
    <a
      href="https://www.linkedin.com/in/caro-willers-marketing-specialist/"
      target="_blank"
    >
      <img src="assets/icons/linkedin.png" alt="LinkedIn Icon" />
    </a>
    <a href="#contact" class="contact-link">
      {{ "hero.contact_email" | translate }}
    </a>
  </div>

  <div class="scroll-down">
    <div class="text-and-arrow">
      <div class="arrow-down">{{ "hero.scroll_down" | translate }}</div>
      <div class="arrow">&#x2193;</div>
    </div>
  </div>
</section>
