<section id="about-me" class="about-section">
  <div class="about-me">
    <h1 class="h1" pAnimateOnScroll>{{ "about_me.title" | translate }}</h1>
    <div class="about-me-container">
      <div class="about-me-text">
        <p class="about-me-intro">{{ "about_me.intro" | translate }}</p>

        <div
          class="about-me-paragraph"
          *ngFor="let paragraph of 'about_me.paragraphs' | translate"
        >
          <img [src]="paragraph.icon" alt="Icon" class="icon" />
          <p [innerHTML]="getSafeHtml(paragraph.text)"></p>
        </div>
      </div>
      <div class="profile-picture-container">
        <img
          src="assets/caro_round.png"
          alt="My Profile Picture"
          class="profile-picture"
        />
      </div>
    </div>
  </div>
</section>
