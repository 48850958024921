<!-- privacy-policy.component.html -->
<section class="privacy-policy-container">
  <div class="go-back-container">
    <a routerLink="/" class="go-back-link">
      <img
        class="go-back-btn"
        src="/assets/icons/arrowupDefault.png"
        alt="back to startpage"
      />
      <span class="go-back-text">{{ "impressum.back" | translate }}</span>
    </a>
  </div>
  <div class="privacy-policy">
    <h1>{{ "privacy_policy.title" | translate }}</h1>

    <h2>{{ "privacy_policy.overview_title" | translate }}</h2>

    <h3>{{ "privacy_policy.general_info_title" | translate }}</h3>
    <p>{{ "privacy_policy.general_info_text" | translate }}</p>

    <h3>{{ "privacy_policy.data_recording_title" | translate }}</h3>

    <h4>{{ "privacy_policy.controller_title" | translate }}</h4>
    <p>{{ "privacy_policy.controller_text" | translate }}</p>

    <h4>{{ "privacy_policy.data_collection_how_title" | translate }}</h4>
    <p>{{ "privacy_policy.data_collection_how_text" | translate }}</p>

    <h4>{{ "privacy_policy.data_usage_title" | translate }}</h4>
    <p>{{ "privacy_policy.data_usage_text" | translate }}</p>

    <h4>{{ "privacy_policy.user_rights_title" | translate }}</h4>
    <p>{{ "privacy_policy.user_rights_text" | translate }}</p>

    <h2>{{ "privacy_policy.hosting_title" | translate }}</h2>
    <p>{{ "privacy_policy.hosting_text" | translate }}</p>

    <h3>{{ "privacy_policy.provider_title" | translate }}</h3>
    <p>{{ "privacy_policy.provider_text" | translate }}</p>

    <h4>{{ "privacy_policy.general_info_title_mandatory" | translate }}</h4>

    <h3>{{ "privacy_policy.storage_duration_title" | translate }}</h3>
    <p>{{ "privacy_policy.storage_duration_text" | translate }}</p>

    <h3>{{ "privacy_policy.right_data_portability_title" | translate }}</h3>
    <p>{{ "privacy_policy.right_data_portability_text" | translate }}</p>

    <h3>{{ "privacy_policy.ssl_tls_title" | translate }}</h3>
    <p>{{ "privacy_policy.ssl_tls_text" | translate }}</p>

    <h2>{{ "privacy_policy.social_media_title" | translate }}</h2>

    <h3>{{ "privacy_policy.linkedin_title" | translate }}</h3>
    <p>{{ "privacy_policy.linkedin_text" | translate }}</p>

    <h2>{{ "privacy_policy.analytics_title" | translate }}</h2>

    <h3>{{ "privacy_policy.linkedin_insight_title" | translate }}</h3>
    <p>{{ "privacy_policy.linkedin_insight_text" | translate }}</p>
  </div>
</section>
